.settings {
  &__login-management {
    p {
      margin-top: 40px;
    }
  }

  &__password-forget,
  &__password-change {
    margin: 50px 0;
  }
}
.settingsbox {
  border-radius: 5px;
  box-shadow: 1px 2px 2px rgb(85 85 85 / 30%);
  background-color: #fff;
  padding: 1em 1em 4em 1em;
  margin-bottom: 1em;
  input {
    background-color: #eee;
  }
}