.questioncard {
    width: 100%;
    height: fit-content;
    border-radius: 1rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid #eee;
    display: block;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
        border: 1px solid #aaa;
        background-color: #fafafa;
    }
    .questioncard-inner-container {
        display: flex;
        flex-direction: row;
        position: relative;
        .questioncard-content {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 1rem 0rem 0.5rem;
            width: 100%;
            .questioncard-content-inner-container {
                display: flex;
                flex-direction: row;
                .questioncard-index {
                    display: none;
                    flex: 0 1 0%;
                    p {
                        padding: 0px 1rem;
                        font-size: 1.3rem;
                        margin: 0rem;
                    }
                }
                .questioncard-question {
                    margin-left: 15px;
                    flex: 1 1 0%;
                    overflow: hidden;
                    p {
                        margin: 0rem;
                        overflow: hidden;
                        padding: 3px 0px;
                    }
                }
                .questioncard-tags-container {
                    color: rgb(103, 107, 133);
                    display: flex;
                    align-items: center;
                    margin-right: 0.5em;
                    margin-top: -0.5em;
                    .questioncard-tags {
                        background: rgba(235, 113, 121, 0.15);
                        margin: 0rem 1rem;
                        padding: 0.4rem 0.7rem 0.4rem 0.5rem;
                        border-radius: 1rem;
                        height: fit-content;
                        display: flex;
                        .questioncard-tag {
                            border: 2px solid rgb(235, 113, 121);
                            width: 1rem;
                            height: 1rem;
                            border-radius: 2rem;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }
        }
        .questioncard-actions {
            color: rgb(103, 107, 133);
            display: flex;
            flex-direction: row;
            position: relative;
            padding: 1rem 0rem 0rem;
            min-width: 20%;
            button {
                border: none;
                padding: 0.5rem 0.75rem;
                border-radius: 0.75rem;
                position: absolute;
                top: 0.25rem;
                right: 0.25rem;
                margin: 0.25rem 0.5rem;
                cursor: pointer;
                svg {
                    height: 1.05rem;
                    opacity: 0.6;
                }
            }
        }
    }
}


@media only screen and (max-width: 600px) {
    .questioncard {
        width: 100%;
        height: fit-content;
        border-radius: 1rem;
        background-color: rgb(255, 255, 255);
        display: block;
        margin-bottom: 1rem;
        cursor: pointer;
        .questioncard-inner-container {
            display: flex;
            flex-direction: row;
            position: relative;
            .questioncard-content {
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 1rem 0rem 0.5rem;
                width: 100%;
                .questioncard-content-inner-container {
                    display: flex;
                    flex-direction: column;
                    .questioncard-index {
                        display: none;
                        p {
                            padding: 0px 1rem;
                            font-size: 1.3rem;
                            margin: 0rem;
                        }
                    }
                    .questioncard-question {

                    }
                    .questioncard-tags-container {
                        height: 2em;
                        margin-top: 1em;
                        .questioncard-tags {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}