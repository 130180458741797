.gamecard {
    display: flex;
    margin: 0 0 1rem 0;
    width: 17.5rem;
    cursor: pointer;
    border: 1px solid $color-secondary-shadow-light;
    margin: 5px;
    border-radius: 19px;
    background-color: #ffffff;
    transition: background-color 0.4s;
    transition: border 1s;
    
    .icon {
        min-width: 5rem;
        min-height: 5rem;
        border-radius: 18px;
        background: linear-gradient(220.53deg, #161B1E 30.26%, #959595 95.39%);
        background: linear-gradient(220.53deg, rgb(73 124 155) 30.26%, rgb(200 207 225) 95.39%);
        text-align: center;
        margin: 5px;
        transition: opacity 0.4s;
    }
    .text {
        padding-left: 5px;
    }
    &:hover {
        background-color: $color-secondary-hover-background;
        border: 1px solid rgb(113, 124, 153);
        .icon {
            //background: linear-gradient(200.53deg, rgb(73 124 155) 30.26%, rgb(200 207 225) 95.39%);
            opacity: 0.8;
        }
    }
    &:active {
        opacity: 0.5;
        border: 1px solid rgb(113, 124, 153);
        .icon {
            //background: linear-gradient(200.53deg, rgb(73 124 155) 30.26%, rgb(200 207 225) 95.39%);
            opacity: 1;
        }
    }
}



.overview-event-cards-container {
    display: flex;
    overflow: auto;
    padding-bottom: 0rem;
    
    .overview-event-card {
      
      &-container {
        cursor: pointer;
        padding: 15px 50px 0px 20px;
        font-size: 1.2em;
        margin-bottom: 1rem;
        background: $color-white;
        border-radius: 10px 10px 10px 10px;
        -moz-border-radius: 10px 10px 10px 10px;
        -webkit-border-radius: 10px 10px 10px 10px;
        box-shadow: 1px 2px 1px $color-box-shadow;
        margin-right: 1.3rem;
        position: relative;
        display: inline-flex;
        .map {
          width: 100%;
          height: 50px;
          .selected-questions-container {
            height: 50px;
          }
        }
        .icon {
            padding: 10px 5px;
            border-radius: 1em;
            width: 5rem;
            height: 5rem;
            color: white;
            text-align: center;
            margin-right: 1.5rem;
            position: relative;
            font-size: 2rem; 
        }
        /*.outside {
            position: absolute;
            background-color: #ffffff;
            width: 2.2rem;
            height: 1.2rem;
            top: 0;
            left: 0;
            border: 1px solid rgb(235 113 121);
            border-radius: 1rem 0 1rem 0;
        
        }
        .inside {
            position: absolute;
            background-color: #ffffff;
            width: 2.2rem;
            height: 1.2rem;
            top: 0;
            left: 0;
            border: 1px solid rgb(235, 146, 113);
            border-radius: 1rem 0 1rem 0;    
        }*/
        
      }
  
      &-container:hover {
        background: $color-white;
        box-shadow: 6.2px 6.2px 10px 0px $color-box-shadow-hover;
        transition: box-shadow 0.2s ease-in-out;
      }
  
      &-top {
        display: flex;
        width: 100%;
        flex-direction: column;
      }
  
      &-title {
        font-size: 21px;
        margin-right: auto;
        color: $color-main;
      }
  
      &-code {
        display: flex;
        font-size: 0.8em;
        margin-top: 0.5em;
        color: $color-main-red;
      }
  
      &-icons {
        display: flex;
        color: $color-faded-main;
        justify-content: flex-start;
        padding: 12px 4px 6px 4px;
        font-size: 1em;
  
        img {
          height: 1em;
          padding-right: 5px;
        }
      }
  
      &-time-container {
        display: flex;
        flex: 80%;
        justify-content: flex-start;
      }
  
      &-players-container {
        display: flex;
        flex: 80%;
        justify-content: flex-start;
        margin-left: 1.0rem;
      }
  
      &-questions-container {
        display: flex;
        flex: 80%;
        justify-content: flex-start;
        margin-left: 1.0rem;
      }
      &-delete-container {
        display: flex;
        justify-content: flex-end;
      }

     

    }
  }
  
  .overview-event-cards-container.dark {
    .overview-event-card {
      &-container {
        background: $color-white-background;
        border-radius: 10px 10px 10px 10px;
        -moz-border-radius: 10px 10px 10px 10px;
        -webkit-border-radius: 10px 10px 10px 10px;
        box-shadow: 1.2px 1.2px 1px 0px $color-box-shadow-hover;
      }
  
      &-container:hover {
        background: $color-white-shadow;
      }
  
      &-title {
        color: $color-faded-main;
      }
  
      &-code {
        color: $color-faded-main;
      }
  
      &-icons {
        display: flex;
        justify-content: flex-start;
        padding: 6px 4px;
        font-size: 1em;
  
        img {
          height: 1em;
          padding-right: 5px;
        }
      }
      
    
  }
  }