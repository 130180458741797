.login {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
 

  &__register__buttons {
    display: flex;
    flex-direction: row;
    gap: 0px 20px;
  }

  &__image {
    position: relative;
    flex: 4;
    width: 80%;
    background-color: rgba($color-main, 0.4);
    z-index: 3;

    clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
    @include breakpoint('phone') {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      right: -20px;
      background-image: linear-gradient(
          to bottom,
          rgba($color-main, 0.9) 10%,
          rgba($color-main, 0.7),
          rgba($color-main, 0.85) 90%
        ),
        url('https://cdn.pixabay.com/photo/2017/01/17/03/57/desktop-1985856_960_720.jpg');

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center right;
      clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  &__content {
    flex: 3;
    background-color: $color-main;
    z-index: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include breakpoint('phone') {
      flex-grow: 3;
      flex-basis: auto;
    }

    &__inner {
      max-width: 400px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }

    &__providers {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      width: 100%;

      &--left {
        margin-right: 50px;
      }

      & > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 30px;
      }

      &__item {
        flex: 1;

        &:first-child {
          margin-right: 5px;
        }

        &:last-child {
          margin-left: 5px;
        }

        button {
          border: none;
          width: 100%;
          height: 100%;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    &__or {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 30px 0;

      &__line {
        height: 1.8px;
        flex: 1;
        width: 100%;
        background-color: $color-main;
      }

      &__text {
        margin: 0 20px;
      }
    }

    &__creator {
      display: flex;
      justify-content: center;
      align-items: center;

      &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20%;
        margin-top: 20px;

        > div {
          margin-right: 10px;
        }
      }
    }

    &__password-forget {
      margin-top: 5px;
      a {
        color: #59b9eb;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: white;
        }
      }
    }

    &__register {
      a {
        color: rgba($color-faded-main, 0.9);
        transition: all 0.3s ease-in-out;

        &:hover {
          color: white;
        }
      }
    }

    &__login-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 3;
    }

    &__form {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      button {
        min-height: 50px;
        display: block;
      }
    }

    > div {
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex: 2;
      margin-top: -3.5rem;
      margin-bottom: -2.5rem;
      @include breakpoint('phone') {
        flex-grow: 2;
        flex-basis: auto;
      }

      &__title {
        font-size: 2.5rem;
        font-weight: 600;
        color: $color-main;
        margin-bottom: 30px;
        text-align: center;
        @include breakpoint('phone') {
          font-size: 2rem;
        }
      }

      &__logo {
        height: auto;
        width: 400px;
      }
    }

    &__form {
      form {
        display: flex;
        flex-direction: column;
      }
    }
  }
}




@media only screen and (max-width: 600px) {

  .login__content__header__logo {
    width: 70%;
  }
  .login__content {
    padding: 1em;
  }

}