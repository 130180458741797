.btn {
  position: relative;

  display: block;
  margin: 30px auto;
  margin-bottom: 0;
  padding: 0;

  overflow: hidden;

  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px $color-main-shadow;

  background-color: $color-secondary;
  color: $color-white-background;

  transition: background-color 0.3s;
  width: 100%;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $color-secondary-hover;
  }

  > * {
    position: relative;
  }

  span {
    display: block;
    padding: 18px 24px;
  }

  &:before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 0;
    padding-top: 0;

    border-radius: 100%;

    background-color: rgba(236, 240, 241, 0.3);

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &:active {
    &::before {
      width: 120%;
      padding-top: 120%;

      transition: width 0.2s ease-out, padding-top 0.2s ease-out;
    }
  }

  &--disabled {
    cursor: not-allowed;
    user-select: none;
    background-color: darken($color-main, 25%);

    &:hover {
      background-color: darken($color-main, 25%);
    }

    > * {
      pointer-events: none;
    }
  }
}
