$color-main: #333;
$color-secondary: #59b9eb;
$color-secondary-hover: #73ceff;
$color-secondary-hover-background: #eff8fd;
$color-secondary-shadow: #b5e5ff;
$color-secondary-shadow-light: #eef9ff;

$color-main-red: #eb7179;
$color-main-red-hover: #b04a51;
$color-secondary-red: #56353a;
$color-light-red: rgb(255, 230, 231);

$color-main-green: #1cc065;
$color-main-green-hover: #20d873;

$color-black: black;
$color-text: black;
$color-text-secondary: rgb(41, 41, 41);

$color-main-shadow: rgb(59, 59, 59);
$color-faded-main: #7a7e94;

$color-button-background: #676b85;
$color-button-hover: #7a7e94;

$color-border-main: #757575;

$color-white-shadow: #e0e1eb;
$color-navbar-shadow: #55555533;
$color-box-shadow: rgba(103, 107, 133, 0.15);
$color-box-shadow-hover: rgba(0, 0, 0, 0.2);

$color-white-background: #ecf0f1;
$color-white: white;

$navbar-width: 250px;
