.poplar-tag-container {
  display: flex;
  height: fit-content;
  justify-content: space-between;
  align-items: flex-start;

  .poplar-tag {
    cursor: pointer;
    color: $color-white;
    font-size: 10px;
    width: fit-content;
    padding: 5px;
    border-radius: 3px;
    background: $color-main-red-hover;
    white-space: nowrap;

    &:hover {
      background: $color-secondary-red;
    }
  }

  .poplar-tag.checked {
    background: $color-secondary;
  }
}
