.game-overview-container {
  overflow: hidden;
  height: 100%;

  .game-overview-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    .game-overview-banner {
      display: flex;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 208px;
      .selected-questions-container {
        height: 100%;
      }
      > img {
        position: absolute;
        width: 100%;
        top: -140px;
      }
    }

    .game-overview-info-container {
      display: flex;
      flex-direction: column;
      padding: 1em;

      .game-overview-name {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }

      .game-overview-info {
        display: flex;
        .game-overview-numq {
          display: flex;
          margin-right: 1em;

          > img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.2rem;
          }
        }

        .game-overview-outside {
          color: $color-main-red;
        }
      }
    }

    .game-overview-questions {
      display: flex;
      flex-direction: column;
      padding: 1em;
      overflow-y: auto;
      border-top: 0.5px solid lightgray;

      .game-overview-question-container {
        margin-bottom: 1em;

        .game-overview-question-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          box-shadow: 0px 4px 10px 0px $color-white-shadow;
          padding: 0.5em 1.5em;
          border-radius: 20px;

          .game-overview-question-left {
            display: flex;
            flex-direction: row;

            .game-overview-question-number {
              font-size: 2em;
              width: 2.5em;
              margin: auto 0;
            }

            .game-overview-question-text {
              margin: auto 0;
              max-width: 90%;

              .game-overview-question-answer {
                display: flex;
                flex-direction: row;
              }
            }
          }

          .game-overview-question-right {
            display: flex;

            .game-overview-question-type-container {
              display: flex;
              flex-direction: row;
              background: $color-light-red;
              padding: 0.5em 1em;
              border-radius: 62px;
              align-items: center;

              .game-overview-question-type-icon {
                margin: 0 auto;
                border: 2px solid $color-main-red;
                width: 1rem;
                height: 1rem;
                border-radius: 2rem;
                margin-right: 0.5em;
              }

              .game-overview-question-type {
                margin: auto 0;
              }
            }
          }
        }

        .game-overview-question-content:hover {
          background: $color-white-background;
        }
      }
    }

    .game-overview-footer {
      display: flex;
      justify-content: center;
      padding: 1em;

      .game-overview-button-container {
        .game-overview-use-game-button {
          width: 12em;
          height: 3em;
          border-radius: 2em;
          background: $color-main-green;
          color: $color-white-background;
          border: none;
          cursor: pointer;
        }

        .game-overview-use-game-button:hover {
          background: $color-main-green-hover;
        }

        .game-overview-use-game-button:focus {
          outline: none;
        }
      }
    }
  }
}
