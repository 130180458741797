.main {
  padding-left: $navbar-width;
  margin-top: 45px;
  margin-bottom: 50px;

  .container {
    margin: 0 20px;
    
    
    


    .main-header-container { 
      border-radius: 5px 5px 0 0;
      //box-shadow: 2px 2px 2px $color-navbar-shadow;
      border-radius: 5px;
      padding: 0 20px;
      //background-color: #ffffff;
      margin-bottom: 1em;
      .main-header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .head-container {
          flex-basis: 65%;
          margin-right: 20px;
          h1 {
            margin-bottom: 10px;
          }
        }
        .search-bar-container {
          flex-basis: 35%;
          input {
          background-color: #fff;
          border: 1px solid #ccc,
          }
        }
      }
      .main-header-bottom {
        padding-bottom: 0.1em;
        p {
          margin: 0;
        }
      }
    }
    .content-container {
      h2 {
        font-weight: 500;
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  .main {
    padding-left: 0;
    margin-top: 65px;
    .container {
      margin: 0 5px;  
      .main-header-container { 
        padding: 0 20px;
        .main-header-top {
          .head-container {
            flex-basis: 50%;
            margin-right: 20px;
            h1 {
              font-size: 1.5em;

            }
          }
          .search-bar-container {
            flex-basis: 50%;
          }
        }
      }
    }
  }
  body .container {
    padding-left: 0;
    margin: 10px;
  }
  body .games-container {
    padding-left: 0;
  }
 
}