
.my-questions {
  background-color: #fff;
  box-shadow: 2px 2px 2px $color-navbar-shadow;
  border-radius: 5px;
  .my-questions-container {
    display: flex;
    padding: 1em;
    .mobile-filter-button {
      display: none;
      border-radius: 1em;
      background-color: $color-secondary;
      color: #fff;
      cursor: pointer;
      padding: 8px;
      margin: auto;
      &:active {
        opacity: 0.8;
      }
    }
    .filter-container {
      width: 300px;
      background-color: #fff;
    }
    .questions {
      background-color: #fff;
      box-shadow: inset 1px 1px 1px $color-navbar-shadow;
      padding-left: 10px;
      width: 100%;
      .box {
        margin-bottom: 40px;
        width: 100%;
        min-height: 11em;
          max-height: 30em;
          overflow-y: auto;
        .list {
          
        }
        .filterlist {
        }
      }
    }
  }
}



.editMyQuestionButtonStyle {
  margin: 0;
  padding: 0 0.5rem;
}



@media only screen and (max-width: 600px) {
  .my-questions {
    .main-header-container {
      
      .main-header-bottom {
        display: none;
      }
    }
    .my-questions-container {
      flex-direction: column;
      padding: 0;
      .mobile-hide-filter {
        display: none;
      }
      .mobile-filter-button {
        display: block;
      }
      .questions {
        box-shadow: none;
      }
    }
  }
}