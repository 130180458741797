.signout-btn {
  color: $color-main;
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  padding: 5px;

  &:hover {
    color: rgba($color-main-red, 0.8);
  }
}
