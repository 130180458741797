.poplar-input-container {
  width: 100%;
}

.poplar-input {
  border-radius: 5px;
  padding: 0.5rem;
  width: 100%;
}

.poplar-input-text {
  color: $color-main;
  font-size: 10px;
  margin: 0.5rem 0 0 0;
}
