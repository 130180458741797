.poplar-button {
  cursor: pointer;
  display: flex;
  border-radius: 3px;
  padding: 0.5rem;
  width: 11rem;
  background-color: $color-button-background;
  color: $color-white;
  justify-content: center;
  align-content: center;

  .poplar-button-text {
    margin: auto;
    display: inline-block;
    max-height: fit-content;
  }
}

.poplar-button:hover {
  background: $color-button-hover;
}

.green {
  background-color: $color-main-green;
  transition-duration: 350ms;
}

.green:hover {
  background-color: $color-main-green-hover;
}
