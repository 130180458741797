.member-textfield {
  margin-right: 10px;
  margin-bottom: 10px;
  width: auto;
  flex-grow: 1;
}

.admin {
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px;
    list-style: none;

    &__link {
      text-decoration: none;
      color: $color-main;

      &:hover,
      &:active {
        font-weight: 800;
      }

      &__active {
        font-weight: 800;
        text-decoration: underline;
        text-decoration-color: $color-main-green;
        text-decoration-thickness: 3px;
      }
    }
  }

  &__users {
    width: 100%;
  }

  &__user {
    padding: 20px;
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 50px;

    &__field {
      font-weight: 400;
    }
  }

  &__stats {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  &__stat {
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 250px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    &__body {
      margin: 10px;
      height: 100%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }

    &__data {
      font-size: 36px;
      font-weight: 800;
      color: $color-white;
      margin: 0 auto;
      text-align: center;
      vertical-align: middle;
    }

    &__icon {
      padding: 5px;
      width: 80px;
      height: 50px;
      background-color: $color-white;
      border-radius: 0px 0px 5px 0px;
    }

    &__title {
      font-weight: 800;
      font-size: 18px;
      padding: 0 10px;
    }
  }
}
