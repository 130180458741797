.questions-container-outer {
  height: 100vh;
.questions-container {
  pointer-events: none;
  padding-top: 27px;
  display: flex;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: auto;
  
  width: 100%;
  height: 100%;
  

  position: relative;
  
  z-index: 12;


  .active-questions {
    width: 22rem;
    background-color: rgba(22, 27, 30, 0.8);
    position: absolute;
    right: 0.7rem;
    top: 10rem;
    z-index: 1;
    border-radius: 0.5rem;
    display: inline-block;
    h3 {
      color: rgb(255, 255, 255);
      margin: 0.8rem 1rem;
    }
    .list {
      width: 22rem;
      max-height: 60vh;
      background-color: rgba(22, 27, 30, 0.8);
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 1;
      border-radius: 0.5rem;
      display: inline-block;
      .item {
        position: relative;
        left: 2px;
        width: 20.5rem;
        min-height: 3rem;
        background-color: rgb(103, 107, 133);
        border-radius: 0.5rem;
        padding: 0 5px;
        color: rgb(255, 255, 255);
        margin: 0.4rem 0.2rem;
        display: flex;
        .number-container {
          display: flex;
          justify-content: center;
          .number {
            
            display: block;
            background-color: #aaa;
            border-radius: 1em;
            width: 30px;
            height: 30px;
            padding-top: 8px;
            text-align: center;
          }
        }
        .title {
          padding-left: 5px;
          padding-right: 65px;
          position: relative;
          span {
            color: #aaa;
            font-size: 11px;
            cursor: pointer;
          }
       
        }
        .button {
          background-color: rgb(235, 113, 121);
          position: absolute;
          right: 0.1rem;
          height: 1.5rem;
          width: 1.5rem;
          border-radius: 10rem;
          text-align: center;
          align-self: center;
          margin: 0px 0.3rem 0px 0.5rem;
          cursor: pointer;
          svg {
            height: 0.75rem;
            width: 0.75rem;
            margin-top: 0.4rem;
            cursor: pointer;
          }
        }
        .hide-button {
          background-color: rgb(113, 152, 235);
          position: absolute;
          right: 2.1rem;
          height: 1.5rem;
          width: 1.5rem;
          border-radius: 10rem;
          text-align: center;
          align-self: center;
          margin: 0px 0.3rem 0px 0.5rem;
          cursor: pointer;
          svg {
            height: 0.75rem;
            width: 0.75rem;
            margin-top: 0.4rem;
            cursor: pointer;
          }
        }
      }
    }
  }





  .back-button {
    position: absolute;
    top: 1px;
    left: 10px;
    border-style: none;
    outline: none;
    width: fit-content;
    padding: 1px 20px;
    height: 25px;
    background-color: #ccc;
    box-shadow: 1px 1px 1px $color-box-shadow;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    svg {
      margin-right: 1em;
    }
  }
  .dropLeftButton {
    transition-duration: 300ms;
    transition-property: transform;
  }
  .dropDownButton {
    transform: rotate(-90deg);
    transition-duration: 300ms;
    transition-property: transform;
  }

  .questions-list-button {
    height: fit-content;
    background-color: $color-main-red;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin: 0 auto;
    box-shadow: 1px 1px 1px rgba($color-main-red, 0.5);
    white-space: nowrap;
    font-size: 0.9rem;
    padding: 0.7rem 0.8rem;
    transition-duration: 200ms;
  }
  .questions-list-button:hover {
    background-color: $color-main-red-hover;
  }
  .back-button:hover {
    background-color: #aaa;
    box-shadow: 1px 1px 1px rgba(22, 27, 30, 0.25);
    
  }
  .back-button:active {
    background-color: #eee;
    box-shadow: 1px 1px 1px rgba(22, 27, 30, 0.25);
    
  }

  .questions-left {
    pointer-events: all;
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    overflow-y: auto;
    max-height: 100%;
    
    background-color: #4e4e4e;

    .questions-left-top {
      display: flex;
      flex-direction: column;
      background-color: #4e4e4e;
      flex-grow: 0;
      flex-shrink: 0;
    }
    .questions-left-bottom {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .questions-left-bottom-top {
        display: flex;
        flex-direction: column;
        padding: 10px;
        flex-grow: 0;
      }
      .questions-left-bottom-bottom {
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding: 10px;
        flex-grow: 1;
      }
    }

    .filters {
      background-color: #fff;
      margin-right: 1px;
      .inner {
        margin: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
      .headers {
        display: flex;
      .filters-header {
        position: relative;
        width: 100%;
        background-color: #eee;
        border-radius: 0px;
        padding: 5px 1em;

        cursor: pointer;
        
        &.open {
          border-radius: 5px 5px 0 0;
        }
        .dropDownButton {
          position: absolute;
          right: 1em;
        }
        .dropLeftButton {
          position: absolute;
          right: 1em;
        }
      }
      
    }
    .search-body {
        background-color: #fff;
      }
      .filters-body {
        background-color: #eee;
        padding: 8px;
        border-radius: 0 0 5px 5px;
        max-height: 50vh;
 
        p {
          margin: 5px 0 0 0;
        }
  
      }
    }
  }

    .tabs-header {
      display: flex;
      flex-grow: 0;
      .item {
        border-radius: 8px 8px 0 0;
        background-color: #ccc;
        text-align: center;
        padding: 5px;
        margin-right: 1px;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        .icon {
          position: absolute;
          top: 2px;
          right: 2px;
          width: 15px;
          height: 15px;
          border-radius: 15px;
          padding-top: 2px;
          text-align: center;
          font-size: 10px;
          background-color: #b04a51;
          color: #fff;
        }
        &.active {
          background-color: #fff;
        }
        &:hover {
          background-color: #fff;
        }
      }
    }
    .tab-body {
      display: none;
      background-color: #fff;
      margin-right: 1px;
      border-radius: 0 0 8px 8px;
      overflow-y: auto;
      //max-height: 70vh;
      &.active {
        display: block;
      }
      .search-bar {
        padding: 1em;
      }
      &.filters-open {
        //max-height: 35vh;
      }
    }


    form {
      display: flex;

      .algolia-search-bar {
        padding: 15px;
        border-radius: 15px;
        background-color: #f3f3f3;
        border: 0px;
        color: black;
        width: 100%;
      }

      .algolia-search-button {
        border-radius: 20px;
        min-width: 62px;
        -moz-border-radius: 20px;
        -webkit-border-radius: 20px;
        background-color: $color-main-red-hover;
        border: 0px;
        color: white;
      }

      .algolia-search-button:hover {
        background: rgba(0, 0, 0, 0.2);
      }

      .algolia-search-button:active {
        background: rgba(190, 190, 190, 0.5);
      }

      .algolia-search-button:focus,
      .algolia-search-bar:focus {
        outline: none;
      }

      .algolia-search-button:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  
  .questions-right {
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    pointer-events: all;
    
    

    .create-game-error-text {
      color: $color-main-red;
    }

    .create-game-container {
      background-color: #4e4e4e;
      height: 55px;
      z-index: 10;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      .icon-container {
        margin-top: 5px;
        border-radius: 8px;
        margin-right: 5px;
        background-color: #aaa;
        text-align: center;
        display: flex;
        .icon {
          color: #fff;
          padding: 4px 0px 0px 6px;
          text-align: center;
          
        }
        .text {
          padding: 6px 10px 5px 5px;
          font-size: 0.7em;
          color: #fff;
          .finished {
            color: rgb(0, 218, 0);
          }
        }
        svg {
          font-size: 18px;
        }
        &.play {
          background-color: #1CC065;

        }
        &.outside {
          background-color: #EB7179;

        }
        &.inside {
          background-color: rgb(235, 146, 113);
        }
        &.live {
          background-color: rgb(216, 120, 231);
        }
        &.outline {
          background-color: #4e4e4e;
          border: 1px solid #aaa;
        }
        &.link {
          cursor: pointer;
        }
        &.link:hover {
          background-color: #aaa;
        }
        &.link:active {
          background-color: #eee;
        }
      }
      .game-type-container {
        position: relative;
        display: flex;
        flex-direction: column;
        .game-type-inner {
          display: flex;
          flex-flow: row wrap;
          border: none;
          margin: auto 0rem;
          justify-content: space-between;
          padding: 0.2rem 0rem 0rem;
        }
        .change-game-container {
          position: absolute;
          top: 3em;
          left: 0;
          padding: 1em;
          background-color: #666;
          border-radius: 5px;
          box-shadow: 2px 2px 2px rgba($color-black, 0.5);
          .text {
            color: #fff;
            margin-bottom: 1em;
          }
          .buttons {
            display: flex;

          }
          .x {
            position: absolute;
            right: 0;
            top: 0;
            padding: 5px;
            color: #aaa;
            cursor: pointer;
          }
        }
      }

      .slider-container {
        color: white;
        min-width: 100px;
        display: block;
        .slider-title {
          font-size: 1em;
        }

        .slider {
          -webkit-appearance: none;
          width: 100%;
          height: 15px;
          border-radius: 5px;
          background: #fff;
          outline: none;
          opacity: 0.6;
          -webkit-transition: 0.2s;
          transition: opacity 0.2s;
        }

        .slider:hover {
          opacity: 0.8;
        }
      }

      .create-game-button-container {
        cursor: pointer;
        min-width: 11rem;
        margin: 0.1rem 0.5rem;
      }
    }

    .create-game-name-container {
      margin: 0.2rem 0.5rem 0.1rem 0.5rem;
      width: 30%;
      min-width: 12rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        margin: 0px 0px 0.2rem 0px;
      }
    }

    .selected-questions-container {
      overflow-y: auto;
      height: 100%;

      .questions-list-container {
        width: 90%;
        margin: 1em auto;
        padding: 1rem;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
        .add-question-button {
          border: 0px solid #aaa;
          border-radius: 20px;
          width: 250px;
          background-color: #eb7179;
          color: #fff;
          padding: 10px;
          cursor: pointer;
          &:hover {
            background-color: #b04a51;
          }
        }
      }

      .markerText {
        font-weight: bolder;
        background-color: rgba(
          255,
          255,
          255,
          0.815
        ); /* Changing background color */
        font-weight: bold; /* Making font bold */
        border-radius: 20px;
        padding: 0.25rem;

        & {
          width: 1.5rem;
        }
      }
    }

    .in-out-nav {
      height: 40px;
      justify-content: space-evenly;
      background-color: #4e4e4e;

      .in-out-button {
        display: flex;
        flex: 50%;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        color: white;
        box-shadow: inset 0px -1px 0px 0px lightgray;
        cursor: pointer;
      }

      .in-out-button.selected {
        box-shadow: inset 0px -3px 0px 0px $color-secondary;
      }
    }

    > div {
      display: flex;
    }

    .map-container {
      .map-style-toggle-button {
        position: absolute;
        right: 60px;
        top: 50px;
        background: $color-main-red;
        padding: 15px;
        cursor: pointer;
        z-index: 99;
        border-radius: 5px;
      }

      .map-style-toggle-button:hover {
        background: $color-main-red-hover;
      }
      // todo:
      .map-location-search-form {
        position: absolute;
        top: 5rem;
        z-index: 99;
        right: 10px;
        .map-location-search-input {
          background: white;
          padding: 11px 15px;
          z-index: 99;
          border-radius: 3px;
          box-shadow: 0px 4px 10px rgba(22, 27, 30, 0.25);
          width: 22rem;
          border-style: none;
        }

        .map-location-search-input:focus {
          outline: none;
        }
      }
    }

    .mapboxgl-map {
      user-select: none;
    }
  }

  .questions-list-container {
    display: flex;
    flex-direction: column;
    
    margin: 0 5px 1rem 5px;
    
    border-radius: 0.5em;
    background-color: #fff;
    

    .questions-list-title {
      display: none;
      margin-top: 5px;

      .questions-list-title-text {
        color: black;
        margin: 10px 0 5px 0;
        font-size: 1.5em;
      }

      .questions-list-title-arrow {
        color: black;
        margin: 10px 0 5px auto;
        font-size: 1.5em;
        transition-duration: 200ms;
      }

      .questions-list-title-arrow:hover {
        margin: 10px 0 5px auto;
        font-size: 1.5em;
        cursor: pointer;
        color: $color-main-red-hover;
      }
    }

    .qustions-list-divider {
      margin-bottom: 10px;
      height: 1px;
      border-width: 0;
      color: gray;
      background-color: gray;
    }

    .question-cards-container {
      display: flex;
      flex-direction: column;

      .question-card-container {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        margin-bottom: 10px;
        background: #fff;
        border-radius: 10px;
        -moz-border-radius: 10px;
        -webkit-border-radius: 1rem;
        //box-shadow: 0px 4px 10px rgba(103, 107, 133, 0.15);
        border: 1px solid rgba(22, 27, 30, 0.25);
        
        
        .drag-click {
          width: 18px;
          height: 18px;
          position: relative;
          border: 1px solid #4e4e4e;
          left: -4px;
          top: 0px;
          padding: 2px;
          margin-right: 3px;
        }
        .selected {
          background-color: red !important;
          
        }
        
        .question-card-content {
          display: flex;
          flex-basis: 100%;
          flex-direction: column;

          
          
          .question-index {
            width: 20px;
          }

          .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0.5rem;
            position: relative;

            .question-text {
              margin-bottom: 5px;
              font-size: 0.8rem;
            }

            .left {
              display: flex;
              justify-content: center;
              flex-direction: column;
              margin-right: 10px;

              .type-points-container {
                display: flex;
                flex-direction: row;

                .question-type {
                  margin-bottom: 5px;
                  color: gray;
                  font-size: 0.9em;
                }

                .question-points {
                  margin-bottom: 5px;
                  color: gray;
                  font-size: 0.9em;
                }

                .question-type-points-divider {
                  color: gray;
                  margin: 0 5px;
                }
              }
            }

            .right {
              display: flex;

              .points-marker-container {
                width: 24px;
              }
            }
          }

          .bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: $color-main-red;
            color: #fff;
            border-radius: 0 0 0.9rem 0.9rem;
            padding: 0.2rem 0.5rem;
            font-size: 12px;

            .question-tags {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              margin-right: 10px;

              > div {
                margin-right: 5px;
              }

              .no-tags {
                font-size: 0.8em;
                font-style: italic;
                margin: auto 0;
              }
            }

            .button-container {
              display: flex;
              flex-direction: column;
              position: absolute;
              top: 0;
              right: 0;

              > div {
                width: 5rem;
                margin-top: auto;
              }
            }
          }
        }

        .question-card-underline {
          width: 100%;
          margin-bottom: 10px;
          height: 1px;
          border-width: 0;
          color: gray;
          background-color: lightgray;
        }
      }

      .question-card-container.draggable {
        cursor: grab;
      }

      .question-card-container.draggable:hover {
        cursor: grab;
        background: rgb(252, 252, 252);
      }

      .question-card-container.draggable:active {
        cursor: grabbing;
        background: rgb(235, 235, 235);
      }
    }
  }
}
}
.questions-left .question-cards-container {
      
}

.new-question-form-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-x: visible;

  .new-question-form-navbar {
    display: flex;
    margin-bottom: 10px;
  }

  .new-question-form-main {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100%;

    > div {
      display: flex;
      flex-direction: column;
      flex: 50%;
      padding: 20px;
    }

    .new-question-form-left {
      background: rgba(22, 27, 30, 0.05);
      padding: 2.5rem;

      textarea {
        resize: none;
        border-radius: 0.3rem;
        border-width: 0px;
        padding: 1rem;
        width: 100%;
        height: 6rem;
      }

      textarea:focus,
      input:focus {
        outline: none;
      }

      .new-question-form-content {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding-right: 10px;
        flex-basis: 100%;

        > div {
          margin-bottom: 25px;
        }

        .class-picker {
          color: $color-main;
          margin-bottom: 0;
        }

        .question-prompt-question-row {
          display: flex;
        }

        .question-prompt-hint-row {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 15px;
        }

        .question-prompt-buttons-row {
          display: flex;
        }

        .question-set-answer {
          min-height: 6rem;
        }
      }
    }

    .new-question-form-right {
      display: flex;
      flex-direction: column;
      background: rgba(22, 27, 30, 0.1);
      padding: 2.5rem;
      overflow-y: auto;
      overflow-x: hidden;

      > div {
        display: flex;
        flex-direction: row;

        .question-settings-entry-header {
          font-size: 1em;
          margin-bottom: 5px;
        }

        .question-settings-points-container {
          display: flex;
          flex-direction: column;
          width: 50%;
          margin-right: 25px;
        }

        .question-settings-time-container {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          width: 50%;

          .question-settings-time-headers {
            display: flex;
            .question-settings-checkbox-label {
              display: flex;
              margin-bottom: 10px;
              margin-left: auto;

              .question-settings-checkbox {
                width: 13px;
                height: 13px;
                margin-left: 10px;
              }
            }
          }

          .question-settings-time-inputs {
            display: flex;
            .question-settings-time-input {
              > input {
                width: 100%;
              }
            }

            > div:not(:last-child) {
              margin-right: 25px;
            }
          }
        }

        .question-settings-class-subject-container {
          display: flex;
          flex-direction: row;
          flex-basis: 100%;

          > div {
            display: flex;
            flex-direction: column;
            flex-basis: 50%;

            > input {
              width: 50%;
            }
          }

          > div:not(:last-child) {
            margin-right: 25px;
          }
        }

        .tag-manager-container {
          display: flex;
          flex-direction: column;

          .tag-manager {
            max-width: 100%;
            box-sizing: border-box;

            .tag-manager-tags-container {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: flex-start;

              .tag-manager-tag-container {
                margin-right: 5px;
                margin-bottom: 10px;
                height: 30px;

                .tag-manager-tag {
                  cursor: pointer;
                  height: 30px;
                  padding: 2px 10px;
                  border-radius: 52px;
                  border: 3px solid rgba(22, 27, 30, 0.52);
                }

                .tag-manager-tag.checked {
                  color: white;
                  border: 3px solid rgba(22, 27, 30, 0);
                  background: rgba(22, 27, 30, 0.75);
                }
              }
            }

            .tag-manager-top {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .tag-manager-new-tag-container {
                margin-right: 2em;
                .tag-manager-new-tag-label {
                  display: inline-block;
                  margin-bottom: 5px;
                }

                .tag-manager-new-tag {
                  display: flex;
                  flex-direction: row;
                  margin-bottom: 20px;

                  .tag-manager-input {
                    margin-right: 0px;
                  }

                  .tag-manager-button {
                    height: 35px;
                    width: 35px;
                    font-size: 2em;
                    padding: 0px;
                  }
                }
              }

              .tag-manager-language-selector {
                width: fit-content;

                .tag-manager-language-selector-label {
                  display: inline-block;
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }

      .new-question-form-complete-buttons {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        .question-form-error-message {
          margin-bottom: 5px;
        }

        .question-prompt-button.complete {
          margin-bottom: 5px;
        }

        .question-prompt-button.complete:hover {
          background: $color-main-red;
        }
      }
    }
  }

  .new-question-form-footer {
    display: flex;
    margin-top: auto;
    justify-content: flex-end;
    padding-top: 10px;
    align-items: center;

    .question-prompt-button.prev {
      margin-right: auto;
    }
  }
}

.mapboxgl-map {
  user-select: none;
}

.map-marker {
  cursor: pointer;
}

.question-prompt-button {
  width: fit-content;
  white-space: nowrap;
  border: none;
  padding: 0 1rem;
  width: fit-content;
  height: 45px;
  background: $color-button-background;
  border-radius: 31px;
}

.question-prompt-button:not(:last-child) {
  margin-right: 10px;
}

.question-prompt-input {
  //margin-right: 10px;
  margin-bottom: 10px;
  //width: auto;
  width: 100%;
  flex-grow: 1;
}

.question-form-practical {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .question-form-answer-type {
    flex-basis: 50%;
    margin-right: 25px;
    margin-bottom: 10px;
    overflow: visible;
  }

  .poplar-number-input {
    width: 112px;
    height: 38px;
  }
}

.question-form-set-answer-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .question-form-set-answer-input-container {
    display: flex;
    height: fit-content;
    margin-bottom: 15px;
    justify-content: center;

    .poplar-input-container {
      height: 38px;
    }

    .multiple-choice-checkbox {
      position: relative;
      right: 2rem;
      height: 1rem;
      width: 1rem;
      margin: auto 1rem auto 0rem;
    }
  }
}

.subject-picker {
  margin-bottom: 10px;
  color: $color-main;
}

.number-question-input {
  width: 100%;

  > input {
    width: 100%;
  }
}

div.number-answer-min-max div.number-question-input {
  //display: flex;
  //flex-direction: row;
  margin-right: 1rem;
}

.file-input-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.question-prompt-upload-button {
  cursor: pointer;
  display: flex;
  background: rgba(103, 107, 133, 0.45);
  color: white;
  justify-content: center;
  align-content: center;
  border-radius: 2rem;
  transition-duration: 150ms;
  white-space: nowrap;
  width: fit-content;
  height: max-content;
  padding: 1rem;
}
.question-prompt-upload-button:hover {
  background: rgba(161, 164, 191, 0.45);
}

.question-propmpt-upload-button:not(:last-child) {
  margin-right: 10px;
}

.question-propmpt-upload-button:hover {
  background: $color-main-red-hover;
}

.modal-exit-button {
  position: absolute;
  right: -10px;
  top: -10px;
  display: flex;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  background: white;
  margin-left: auto;
  cursor: pointer;
}

.modal-exit-button:hover {
  background: lightgray;
  color: white;
}

.practical-number-input:not(:last-child) {
  margin-right: 25px;
}

.multiple-choice-remove-button {
  display: flex;
  border-radius: 50%;
  margin: auto;
  min-width: 26px;
  min-height: 26px;
  max-width: 26px;
  max-height: 26px;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  background: $color-main-red;
  color: white;
  transition-duration: 150ms;
}

.multiple-choice-remove-button:hover {
  background: $color-main-red-hover;
}

.multiple-choice-add-button-container {
  display: flex;
  flex-basis: 100%;

  .multiple-choice-add-button {
    margin: 0 auto;
    border-radius: 999px;
    margin-left: auto;
    width: 40px;
    text-align: center;
    font-size: 2em;
    color: black;
    cursor: pointer;
  }

  .multiple-choice-add-button:hover {
    color: gray;
  }
}

.number-answer-min-max {
  display: flex;
}



.simple-option-container {
  margin-top: 2px;

  .or-separator {
    margin: 10px;
    font-size: 18px;
    margin: auto;
    width: 100%;
    text-align: center;
    color: #888;
  }
  .and-separator {
    margin: 10px;
    font-size: 22px;
    margin: auto;
    width: 100%;
    text-align: center;
  }
  
}
.simple-option {
  border: 0px solid #aaa;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
  input {
    border: 2px solid #555;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    margin: 2px 20px;
  }
}
.simple-variant-container {
  margin-top: 2px;
}
.simple-variant {
  border: 0px solid #aaa;
  border-radius: 10px;
  display: flex;
}
.simple-desc-button {
  display: flex;
  width: 100%;
  margin-top: 7px;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  color: #000;
  transition-duration: 150ms;
  
  div {
    width: 150px;
    padding: 5px 20px;
    color: #427ac0;
    text-align: center;
    border-radius: 5px;
    svg {
      position: relative;
      top: 2px;
    }
    /*
    border: 1px solid #aaa;
    background-color: #dde5ef;
    &:hover {
      background: #aaa;
    }*/
  }
}
.simple-desc {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  border: 0px solid #aaa;
  border-radius: 5px;
  font-size: 12px;
  background-color: #dde5ef;
  padding: 5px;
}
.simple-warning {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  border: 0px solid #aaa;
  border-radius: 5px;
  font-size: 12px;
  background-color: #efe2a9;
  padding: 5px;
}


.simple-add-button {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  border: 1px solid #aaa;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  background-color: #eee;
  padding: 5px;

  svg {
  display: flex;
  border-radius: 50%;
  padding: 4px;
  background: #1CC065;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: white;
  transition-duration: 150ms;
  }
}
 
.simple-add-button:hover {
  background: #aaa;
}
.simple-remove-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
.simple-remove-button {
  display: flex;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  background: $color-main-red;
  color: white;
  transition-duration: 150ms;

}

.simple-remove-button:hover {
  background: $color-main-red-hover;
}
.simple-variant-remove-button {
  display: flex;
  border-radius: 50%;
  width: 22px;
  height: 19px;
  margin-top: 7px;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  background: $color-main-red;
  color: white;
  transition-duration: 150ms;
  &:hover {
    background: $color-main-red-hover;

  }
}


.simple-add-button-container {
  display: flex;
  flex-basis: 100%;

  .simple-add-button {
    margin: 0 auto;
    border-radius: 999px;
    margin-left: auto;
    width: 40px;
    text-align: center;
    font-size: 2em;
    color: black;
    cursor: pointer;
  }

  .simple-add-button:hover {
    color: gray;
  }
}


















.question-card-print {
  display: none;
  width: 100%;
  background-color: white;
  height: 100%;
  overflow: hidden;

  @media print {
    display: block;
    overflow: hidden;

      h1 {
        text-align: center;
        margin: 0rem auto;
        font-size: 5rem;
        padding-top: 5rem;
        padding-bottom: 2rem;
      }

      h3 {
        text-align: center;
        margin: 0rem auto;
        font-size: 1.6rem;
        padding-bottom: 2rem;
        max-width: 65%;
      }

      p {
        text-align: center;
        margin: 0rem auto;
        font-size: 1.1rem;
        padding-bottom: 4rem;
        font-size: larger;
      }

      img {
        width: 20rem;
        height: 20rem;
      }

      .image {
        margin: 0rem auto;
        width: 20rem;
      }
  }
}

div.game-overview-banner div.questions-right {
    display: block;
    flex-basis: 100%;
    height: 100%;
    width: 100%;
}
div.scoreboard-content div.questions-right {
    height: 78%;
}



@media only screen and (max-width: 600px) {
  .questions-container {
    flex-direction: column;
  }
  .questions-container-outer .questions-container .questions-left {
    border-bottom: 4px solid #4e4e4e; 
    flex-basis: 50%;
  }
  
  .questions-container .questions-right {
    padding-top: 0;
    .create-game-container {
      flex-direction: row;
      flex-wrap: wrap;
      min-height: 60px;
      
    }
  }
  .questions-container .questions-right .map-container .map-location-search-form {
    top:auto;
    left: 5px;
    bottom: 5px;
  }

  .questions-container-outer .questions-container .active-questions {
    display: none;
  }
  
  .questions-container-outer .questions-container {
      pointer-events: all;
      padding-top: 0px;
      .questions-left {
        padding-top: 1.2em;
      }
  }

  .questions-container-outer .questions-container .questions-left .questions-left-bottom .questions-left-bottom-top {
  display: none;
  }

  .questions-container-outer .questions-container .questions-right {
    flex-direction: column-reverse;
    .map-container .map-location-search-form {
      display: none;
      }
      .create-game-container {
        flex-wrap: nowrap;
        overflow: hidden;
        .icon-container.link {
          display: none;
          }
      }
      .create-game-container .game-type-container .change-game-container { 
        top: auto;
        bottom: 1em;
        z-index: 50;
        }
        .create-game-name-container {
          width: 25%;
        }
        .create-game-button-container {
          width: 25%;
          min-width: 15%!important;
          .poplar-button {
            width: 100%;
          }
        }
        .create-game-container .slider-container {
          display: none!important;
        }
  }

}


@media only screen and (max-height: 800px) {
.tab-body {
  max-height: 60vh;
  &.filters-open {
    max-height: 25vh;
  }
}
.questions-container-outer .questions-container .questions-left .filters .filters-body {
  max-height: 35vh;
}
}