.game-card-list {
  display: flex;
      flex-direction: column;
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    .top-right {
      display: flex;
      flex-direction: row;
    }
  }
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.games-container {

 
    .games-list-container {
      display: flex;
      flex-direction: column;
      padding: 0 1em 2em;
      .games-list-title {
        font-size: 1.5em;
        color: $color-main;
        margin-bottom: 10px;
      }
      .sort-container {
        text-align: right;
        position: relative;
        .sort {
          cursor: pointer;
          padding: 5px 20px;
          background-color: $color-secondary;
          border-radius: 5px;
          color: #fff;
          width: 140px;
          float: right;
          text-align: center;
          display: flex;
          svg {
            margin-right: 1em;
          }
        }
        .sort-list {
          background-color: #fff;
          border-radius: 0 0 5px 5px;
          position: absolute;
          right: 5px;
          top: 30px;
          z-index: 2;
          box-shadow: 1px 2px 2px rgb(85 85 85 / 30%);
          ul {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            padding: 20px;
          }
        }
      }
      .game-cards {
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .game-card-container {
          box-sizing: border-box;
          padding: 10px;
          background: $color-white;
          border-radius: 5px;
          box-shadow: 1px 2px 2px rgb(85 85 85 / 30%);
          margin-bottom: 10px;
          justify-content: space-between;
          display: flex;
          width: 99%;
          .game-card-title {
            font-size: 20px;
            cursor: pointer;
          }
          .game-card-code {
              display: flex;
              align-self: flex-end;
              font-size: 0.8em;
              margin-bottom: 0.3em;
              color: $color-main-red;
            
          }
          .date {
            background-color: #eee;
            color: #777;
            border-radius: 5px;
            padding: 3px 5px 1px; 
            font-size: 14px;
            height: 23px;
            margin-left: 10px;
          }
          .card-icons {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-end;
            color: #fff;
            text-align: right;
          .icon-container {
            margin-top: 5px;
            border-radius: 8px;
            margin-right: 5px;
            background-color: #aaa;
            text-align: center;
            display: flex;
            height: 28px;
            .icon {
              color: #fff;
              padding: 4px 0px 0px 6px;
              text-align: center;
              
            }
            .text {
              padding: 8px 10px 5px 5px;
              font-size: 0.7em;
              .finished {
                color: rgb(0, 218, 0);
              }
            }
            svg {
              font-size: 18px;
            }
            &.play {
              background-color: #1CC065;
    
            }
            &.outside {
              background-color: #EB7179;
    
            }
            &.inside {
              background-color: rgb(235, 146, 113);
            }
            &.live {
              background-color: rgb(216, 120, 231);
            }
          }
        }

        }

        .actions {
          position: relative;
          margin-top: 0px;
          display: flex;
          flex-wrap: wrap;
          .button {
            cursor: pointer;
            font-size: 16px;
            border: 1px solid #fff;
            //border-radius: 5px;
            color: $color-secondary;
            padding: 5px 5px 2px;
            margin-right: 5px;
            border-radius: 3px;
            &.qr svg {
              color: #1CC065;
              margin-right: 5px;
            }
          }
          .button:hover {
            background-color: $color-secondary-hover;
            border: 1px solid $color-secondary-hover;
            color: #fff;
          }
          .button:active {
            opacity: 0.8;
          }
          .confirm-overlay {
            position: absolute;
            bottom: 0;
            left: 0px;
            right: 0px;
            background-color: #fff;
            border: 1px solid #aaa;
            padding: 1em;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            text-align: center;
            .buttons {
              display: flex;
              justify-content: center;
            }
           
          }
        }


        .card-event-text {
          color: $color-text-secondary;
          padding: 3px;
          white-space: nowrap;
          font-size: 0.8rem;
        }

        .card-event-text:hover {
          color: $color-faded-main;
          padding: 3px;
          white-space: nowrap;
          font-size: 0.8rem;
          transition-duration: 100ms;
        }
        .card-active-text {
          color: $color-main-green;
          padding: 3px;
          white-space: nowrap;
          font-size: 0.8rem;
        }
        .card-active-text:hover {
          color: $color-main-green-hover;
          padding: 3px;
          white-space: nowrap;
          font-size: 0.8rem;
          transition-duration: 100ms;
        }

        .game-card-container:hover {
          box-shadow: 1px 2px 2px rgb(85 85 85 / 60%);
          transition: 0.2s;
        }

        .game-card-container.selected {
          
          box-shadow: 3px 3px 3px $color-white-shadow;
          transition: 0.2s;
        }
      }
    }
  

  .games-right {
    display: flex;
    flex-direction: column;
    flex: 70%;
    box-sizing: border-box;
    padding: 10px 10px 10px 0;
  }
}
.activateGameModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 14;
  .inner {
    position: absolute;
    top: 1em;
    left: 10%;
    border-radius: 1em;
    overflow: auto;
    width: 80%;
    max-height: 90%;
    margin: 2em auto;
    background-color: #fff;
    z-index: 16;

  }
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffffaa;
    z-index: 15;
  }

}

.create-event-container {
  display: flex;
  flex-direction: column;
  border-radius: 0 1rem 1rem 0;
  height: 100%;

  .create-event-inputs {
    display: flex;
    flex-direction: row;
    height: auto;

    .create-event-left {
      display: flex;
      flex-direction: column;
      flex: 50%;
      background: $color-white-background;
      padding: 40px;

      .create-event-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
      }

      > div {
        display: flex;
        margin-bottom: 1rem;
        flex-direction: column;
      }

      .create-event-message-container {
        > textarea {
          height: 5rem;
          color: $color-faded-main;
        }
      }

      .create-game-time-container {
        display: flex;
        flex-direction: column;

        .create-game-time-inputs {
          display: flex;

          > div:not(:last-child) {
            margin-right: 35px;
          }

          .create-game-time-input-container {
            position: relative;
            .create-game-time-icon {
              position: absolute;
              left: 65px;
              top: 7px;
              height: 1.5rem;
            }

            > input {
              width: 6rem;
              padding: 0.5rem;
              border-radius: 5px;
              border-width: 0px;
              color: $color-faded-main;
              font-size: 0.9em;
            }
          }
        }
      }

      .create-event-checkbox-container {
        .create-event-checkboxes {
          display: flex;
          flex-direction: column;
          .create-event-checkbox-label {
            display: flex;
            margin-bottom: 10px;
            margin-right: 10px;
            font-size: 0.8em;

            .create-event-checkbox {
              margin-right: 10px;
            }
          }
        }
      }

      .create-event-button {
        margin: 0 auto;
        border-radius: 25px;
        width: fit-content;
        padding: 12px;
        background: $color-main-green;
      }

      .create-event-button:hover {
        background: $color-main-green-hover;
        box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
      }

      .create-event-error-text {
        margin: 1rem auto;
        color: #FF0000;
      }
    }

    .create-event-right {
      display: flex;
      flex-direction: column;
      flex: 50%;

      .create-event-banner-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 45%;

        .create-event-banner {
          position: absolute;
          width: 100%;
          top: -70px;
        }
      }

      .create-event-how-to-play-title {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 15px;

        > span {
          font-style: normal;
          font-weight: 500;
          font-size: 26px;
          line-height: 26px;

          text-align: center;
        }
      }

      .create-event-how-to-play-instructions {
        display: flex;
        flex-direction: column;
        justify-content: center;

        > span {
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 139%;

          text-align: center;
        }

        .create-event-how-to-play-1 {
          margin-bottom: 0.5rem;
        }

        .create-event-how-to-play-2 {
          margin: 2rem auto;
        }

        .create-event-how-to-play-3 {
          font-size: 1rem;
          margin-bottom: 2rem;
        }

        .create-event-how-to-play-4 {
          margin-bottom: 0.5rem;
        }

        .create-event-how-to-play-5 {
          margin: 2rem auto;
        }
      }
    }

    .create-event-section-label {
      font-weight: bold;
      margin-bottom: 0.8rem;
    }
  }
}




@media only screen and (max-width: 600px) {

  .games-container .games-list-container .game-cards .game-card-container
  .bottom {
    flex-wrap: wrap;
    &>div {
      width: 99%;
      justify-content: flex-start;
      margin-top: 5px;
    }
  }
  .activateGameModal .inner .create-event-container .create-event-inputs {
    flex-direction: column;
  }

}