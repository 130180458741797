.poplar-number-input-container {
  .poplar-number-input {
    border-radius: 5px;
    padding: 0.5rem;
  }

  .poplar-number-input-text {
    color: $color-main;
    font-size: 10px;
    margin: 0.5rem 0 0 0.5rem;
  }
}
