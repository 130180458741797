.overviewcards {
  width: 100%;
  //background-color: #f0f0f0;
  border-radius: 5px;
  padding-top:10px;

.overview-event-activecards-container {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  padding-bottom: 0rem;
  margin-right: 1em;
  .overview-event-card {
    &-container-outer {
      padding-left: 1em;
      
      width: 50%;
    }
    &-container {
      
      padding: 0.5em;
      font-size: 1.2em;
      margin-bottom: 1rem;
      width: 99%;
      background: $color-white;
      border-radius: 5px;
      box-shadow: 1px 2px 2px rgb(85 85 85 / 30%);
      
      position: relative;
      display: inline-flex;
      flex-direction: column;
      .overview-event-card-icons {
        display: flex;
        flex-wrap: wrap;
        color: #fff;
      .icon-container {
        margin-top: 5px;
        border-radius: 8px;
        margin-right: 5px;
        background-color: #aaa;
        text-align: center;
        display: flex;
        .icon {
          color: #fff;
          padding: 4px 0px 0px 6px;
          text-align: center;
          
        }
        .text {
          padding: 6px 10px 5px 5px;
          font-size: 0.7em;
          .finished {
            color: rgb(0, 218, 0);
          }
        }
        svg {
          font-size: 18px;
        }
        &.play {
          background-color: #1CC065;

        }
        &.outside {
          background-color: #EB7179;

        }
        &.inside {
          background-color: rgb(235, 146, 113);
        }
        &.live {
          background-color: rgb(216, 120, 231);
        }
      }
    }
    }

    &-container:hover {
     box-shadow: 1px 2px 2px rgb(85 85 85 / 60%);
     transition: all 0.3s ease-in-out;
     // background-color: #fafafa;
      
    }
 

    

    &-code {
      display: flex;
      align-self: center;
      font-size: 0.8em;
      margin-bottom: 0.3em;
      color: $color-main-red;
    }

    
   

  }

  .overview-event-card-content {
    position: relative;
    .ending-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #fff;
      border: 1px solid #aaa;
      padding: 1em;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      text-align: center;
      opacity: 0.9;
      z-index: 1;
    }

    display: flex;
    width: 100%;
    flex-direction: column;
    .top {
      position: relative;
      margin-bottom: 5px;
      border-radius: 5px;
      .title {
        
        font-size: 18px;
        margin-right: auto;
        color: $color-main;
        h2 {
          text-align: left;
          margin-bottom: 0;
          cursor: pointer;
          font-weight: 400;
          font-size: 24px;
          margin-top: 0;
        }
      }
    }

    .actions {
      position: relative;
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      .button {
        cursor: pointer;
        font-size: 16px;
        border: 1px solid #fff;
        //border-radius: 5px;
        color: $color-secondary;
        padding: 10px 5px;
        margin-right: 5px;
        border-radius: 3px;
        &.qr svg {
          color: #1CC065;
          margin-right: 5px;
        }
      }
      .button:hover {
        background-color: $color-secondary-hover;
        border: 1px solid $color-secondary-hover;
        color: #fff;
      }
      .button:active {
        opacity: 0.8;
      }
      .confirm-overlay {
        position: absolute;
        bottom: 0;
        left: 0px;
        right: 0px;
        background-color: #fff;
        border: 1px solid #aaa;
        padding: 1em;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        text-align: center;
        .buttons {
          display: flex;
          justify-content: center;
        }
        
      }
    }
  }
}
}


.hideFromPrint {
  @media print {
    display: none;
    visibility: hidden;
  }
}

.showInPrint {
  display: none;
  @media print {
    display: block;
  }
}


.image-gallery-slide img {
  height: 500px;
}

.fullscreen .image-gallery-slide img {
  height: 100vh;
}

.qr-code-container {
  text-align: center;
  img {
    width: 11rem;
    height: 11rem;
  }
}

.scoreboard-container {
  display: flex;
  flex-direction: column;
  .scoreboard-header {
    width: 100%;
    border-bottom: 1px solid rgb(221, 221, 221);
    text-align: center;
    .header-container {
      display: inline-flex;
    align-self: center;
    .modal-exit-button {
      //cancelDeleteButtonStyles
    }
    .text {
      text-align: center;
      h2 {
        font-size: 1.5rem;
        margin-bottom: 0rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
    .pres {
      position: absolute;
      right: 1rem;
      display: none;
      .pres-inner {
        background-color: rgb(103, 107, 133);
        width: 3rem;
        height: 3rem;
        border-radius: 10rem;
        color: white;
        padding: 1rem;
        margin: 1rem 0rem;
        .pres-button {
          border-radius: 5rem;
          border-style: none;
          background-color: rgb(89, 185, 235);
          box-shadow: rgb(89 185 235 / 50%) 0px 4px 10px;
          color: white;
          padding: 1rem;
          margin: 1rem;
        }
      }
    }
    }
  }
  .scoreboard-menu {
      width: fit-content;
      padding: 0.5rem 1rem;
      box-shadow: rgb(103 107 133 / 15%) 0px 4px 10px;
      margin: 0rem 2rem;
      border-radius: 2rem;
      .button {
        outline: none;
        width: 7rem;
        border-style: none none solid;
        background-color: rgb(255, 255, 255);
        padding: 0.5rem;
        border-bottom-width: 4px;
        border-bottom: 4px solid #ffffff;
        cursor: pointer;
        &-active {
          border-bottom: 4px solid #EB7179;
        }
      }
    }
  .scoreboard-top {
    display: flex;
  }

  .scoreboard-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 70vh;
    .questions-right {
      height: 70vh;
      .selected-questions-container {
        height: 70vh;
      }
    }
    .scoreboard-left {
      display: flex;
      flex-basis: 100%;

      .scoreboard-question-list-container {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;

        .scoreboard-question-list-title {
          font-size: 1.7em;
          margin-bottom: 15px;
          color: $color-main;
        }

        .scoreboard-question-list-content {
          color: $color-white;
          display: flex;
          flex-direction: column;

          .scoreboard-question-card-container {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            padding: 0;
            margin-bottom: 1rem;
            background: $color-white;
            border-radius: 1.2rem;
            box-shadow: 0px 4px 10px $color-box-shadow;

            .scoreboard-question-card-top {
              color: $color-main;
              display: flex;
              flex-direction: column;

              .scoreboard-type-points-container {
                display: flex;
                margin-top: 5px;

                .scoreboard-question-card-type {
                  color: $color-main;
                  font-size: 1em;
                }

                .scoreboard-question-card-points {
                  color: $color-main;
                }

                .scoreboard-type-points-divider {
                  color: $color-main;
                  margin: 0 5px;
                }
              }
            }

            .scoreboard-question-card-bottom {
              display: flex;
              justify-content: space-between;
              margin: 0;

              .scoreboard-question-card-teams {
                display: flex;
                flex-wrap: wrap;
                align-content: center;

                .scoreboard-question-card-team-container {
                  background: $color-main;
                  color: $color-white;
                  border-radius: 4px;
                  padding: 2px 6px;
                  height: 22px;
                  margin-right: 5px;
                }

                .scoreboard-question-card-team-container.correct {
                  background: $color-main-green;
                }

                .scoreboard-question-card-team-container.incorrect {
                  background: $color-main-red;
                }
              }

              .scoreboard-question-card-see-more {
                justify-self: flex-end;
              }
            }
          }
        }
      }
    }

    .scoreboard-right {
      width: 100%;
     
      .scoreboard-table-container {
        display: flex;
        flex-basis: 100%;
        padding: 40px;
       
        > table {
          width: 100%;

          > thead {
            > tr {
              > th {
                padding: 5px;
                color: $color-white;
                background: $color-secondary;
              }
            }
          }

          tbody {
            > tr {
              > td {
                background: white;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

.question-see-more-container {
  height: 100%;
  overflow-y: scroll;
  padding: 40px;

  .question-see-more-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .question-see-more-question-info {
      margin-bottom: 25px;

      .question-see-more-question {
        font-size: 1.5em;
        margin-bottom: 5px;
      }

      .question-see-more-subtext {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        > div:not(:last-child) {
          margin-right: 10px;
        }
      }

      .question-see-more-correct-answer {
        .question-see-more-multiple-choice-answer,
        .question-see-more-multiple-answer-answer {
          > table {
            text-align: left;
            width: 100%;

            > thead {
              > tr {
                > th {
                  border-bottom: 1px solid #dddddd;
                  padding: 5px;
                  color: $color-black;
                  font-size: 1.5rem;
                }
              }
            }
            tbody {
              display: block;
              > tr {
                > td {
                  text-align: left;
                  background: $color-white;
                  padding: 0.5rem 2rem 0.5rem 1rem;

                  width: 1rem;
                  color: $color-white;
                  border-radius: 1rem;
                }

                > td.correct {
                  background-color: $color-main-green;
                  box-shadow: 0px 4px 10px rgba(103, 107, 133, 0.15);
                }

                > td.wrong {
                  background-color: $color-main-red;
                  box-shadow: 0px 4px 10px rgba(103, 107, 133, 0.15);
                }
              }
            }
          }
        }
      }
    }
  }

  .question-see-more-user-answers {
    > table {
      width: 100%;

      > thead {
        > tr {
          > th {
            border-bottom: 1px solid #dddddd;
            text-align: left;
            padding: 1rem 0.3rem;
            color: $color-black;
          }

          > th:first-child {
            width: 25%;
          }
        }
      }

      tbody {
        > tr {
          > td {
            padding: 1rem 0.3rem;
            text-align: left;
            background: white;
          }

          > td.correct {
            background: $color-main-green;
          }

          > td.wrong {
            background: $color-main-red;
          }
        }
      }
    }
  }
}



.promo-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0rem;
  margin-right: 1em;
  width: 100%;
  margin: 0 1em;
  max-width: 1000px;
  .promo-card {
    width: 49%;
    position: relative;
    text-decoration: none;
    color: #000;
    a {
      text-decoration: none;
    }
    .image {
      position: absolute;
      top: 0;
      padding-right: 1em;
      height: 180px;
      overflow: hidden;
      border-radius: 5px 5px 0 0;
      img {
        width: 100%;
        border-radius: 5px 5px 0 0;
      }
    }
    .inner {
      background: $color-white;
      border-radius: 5px;
      box-shadow: 1px 2px 2px rgb(85 85 85 / 30%);
      padding: 10px 1em;
      margin-right: 1em;
      margin-bottom: 1em;
      &.with-image {
        border-radius: 0 0 5px 5px;
        padding-top: 180px;
      }
      .head {
        h2 {
          margin: 0;
          padding: 0.5em 0;
        }
      }
      .content {
        p {
          font-size: 1em;
        }
        .button {
          text-align: center;
          cursor: pointer;
          font-size: 16px;
          border: 1px solid #fff;
          //border-radius: 5px;
          color: $color-secondary;
          padding: 10px 15px;
          margin-right: 5px;
          border-radius: 3px;
          display: inline-block;
        }
        .button:hover {
          background-color: $color-secondary-hover;
          border: 1px solid $color-secondary-hover;
          color: #fff;
        }
        .button:active {
          opacity: 0.8;
        }
      }
    }
  }
}



@media only screen and (max-width: 600px) {

  .overviewcards {
     .overview-event-activecards-container {
      margin-right: 1em;
      flex-wrap: wrap;
      .overview-event-card {
        
        &-container-outer {
          width: 100%;
        }
      }
    }
  }
  .promo-container {
    .promo-card {
      width: 95%;
    }
  }
}