.navbar {
  width: $navbar-width;
  left: 0px;
  top: 0px;
  bottom: 0px;
  position: fixed;
  height: 100%;
  background-color: $color-white;
  box-shadow: 2px 2px 2px $color-navbar-shadow;
  overflow: hidden;
  @include breakpoint('phone') {
    height: auto;
  }

  &__inner {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include breakpoint('phone') {
      flex-direction: column;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding-left: 1.5rem;
  }

  .end {
    justify-self: flex-end;
    padding-bottom: 20px;
    text-align: center;
    &>div {
      padding-bottom: 10px;
    }
  }

  &__user {
    padding: 10px;
  }

  svg, path {
    margin-left: 4px;
  }

  &__new-game-button {
    background-color: $color-secondary;
    padding: 8px;
    border-radius: 8px;
    color: $color-white;
    min-width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /*justify-content: space-around;*/
    
    height: fit-content;
    width: 100%;
    position: relative;
    top: -10px;
    svg {
      color: $color-white;
    }
  }

  &__new-game-button:hover {
    
    background-color: $color-secondary-hover;
  }

  &__logo {
    margin-right: 0 auto;

    img {
      max-height: 20vh;
      min-height: 150px;
      height: 20vh;
      width: auto;
    }
  }

  &__links {
    display: flex;
    height: 100px;
    width: 100%;
    flex-direction: column;

    a {
      font-size: 18px;
      min-height: 38px;
      padding: 8px;
      border-radius: 15px 0 0 15px;
      display: flex;
      width: 150px;
      text-decoration: none;
      color: $color-black;
      transition: all 0.1s ease-in-out;
      flex: 1;
      margin-top: 5px;
      margin-bottom: 5px;
      justify-content: space-around;
      width: 100%;

      > div {
        margin: auto;
      }
      
     

      &:hover:not(:first-child) {
        color: $color-secondary-hover;
        background-color: $color-secondary-hover-background;
        border-radius: 15px 0 0 15px;
      }
    }

    &__icon {
      height: 18px;
      width: 22px;
      margin: auto 0;
    }

    &__text {
      margin: auto auto auto 1.0rem;
    }
  }
  
}

.top-navbar {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  z-index: 9;
  height: 27px;
  width: 100%;
  padding-left: 250px;
  text-align: right;
  padding-left: 0;
  background-color: #333333;
  color: #ffffff;
  .wrapper {
    float: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px 5px;
    
    box-shadow: 2px 2px 2px $color-navbar-shadow;
    width: 100%;
    .item {
      font-size: 14px;
      padding-right: 10px;
      svg {
        padding-top: 3px; 
      }
      &.link {
        cursor: pointer;
      }

    }
  }
}


.popmenu {
  &-hide {
    display: none;
  }
  position: fixed;
  top: 28px; 
  right: 5px;
  background-color: #ffffff;
  z-index: 50;
  width: 260px;
  box-shadow: 2px 2px 2px $color-navbar-shadow;
  padding: 20px;
  .userinfo {
    margin-top: 0px; 
    color: $color-main;
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 1px solid $color-secondary-shadow;
    .mail {
      padding-top: 5px;
    }
    svg {
      font-size: 1em;
      margin-right: 5px;
      float: left;
    }
  }
  .item {
    margin: 5px;
  }
  .x {
    position: absolute;
    top: 3px;
    right: 7px; 
    padding: 5px;
    color: #aaa;
    cursor: pointer;
  }
}

.mobile-navbar {
  display: none;
}


@media only screen and (max-width: 600px) {
  .navbar {
    position: fixed;
    left: 0;
    transition: left 1s;
    z-index: 30 !important;
  }
  .mobile-navbar {
    display: flex;
    position: fixed;
    top: -1px;
    left: 0px;
    background-color: $color-white;
    box-shadow: 2px 2px 2px $color-navbar-shadow;
    height: 32px;
    width: 100%;
    z-index: 10;
    .logo {
      width: 50%;
      img {
        height: 50px;
        width: 50px;
        position: relative;
        top: -10px;
        margin-left: 5px;
      }
    }
    .bars {
      width: 50%;
      text-align: end;
      align-self: flex-end;
      padding-right: 20px;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .navbar-mobile-hide {
    left: -250px;
  }
  .userinfo {
    margin-top: 20px; 
    color: $color-main;
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 0px solid $color-secondary-shadow;
    .mail {
      padding-top: 5px;
    }
    svg {
      font-size: 1em;
      margin-right: 5px;
      float: left;
    }
  }
  
}

.new-game-modal {
  position: absolute;
    top: 5%;
    bottom: 5%;
    left: 5%;
    right: 5%;
    min-width: 50%;
    border-radius: 1em;
    overflow: hidden;
    .modal-exit-button {
        position: relative;
        top: 0em;
        right: 0em;
    }
    .desc {
      margin-top: 2em;
      text-align: left;
      font-size: 0.9em;
      color: #555;
    }
}
.new-card-icons {
  display: flex;
  justify-content: space-between;
  div a {
    display: block;
    border-radius: 5px;
    padding: 1em;
    color: #fff;
    text-decoration: none;
  }
  .outside a {
    background-color: #eb7179;
  }
  .inside a {
    background-color: #eb9271;
  }
  .live a {
    background-color: rgb(216, 120, 231);
  }
}