body {
  background: #edeff1;
}
.ReactModal__Overlay {
  z-index: 99;
}

h1 {
  font-weight: 500;
}

.hide {
  display: none!important;
}
.faded {
  opacity: 0.6;
}

.more-button {
  
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    padding: 10px 15px;
    margin-right: 5px;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    background-color: $color-secondary;
  
  &:hover {
    background-color: $color-secondary-hover;
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
}

.only-mobile {
  display: none;
}


@media only screen and (max-width: 600px) {
  .hide-mobile {
    display: none;
  }
  .only-mobile {
    display: block;
  }
  // temp hack to make modals work on mobile
  .ReactModal__Content {
    max-width: 100%!important;
    min-width: 80%!important;
    inset: 10% 3% 10%!important;
    overflow: auto!important;
    .new-question-form-container {
      height: auto!important;
      .modal-exit-button {
        position: fixed!important;
        top: 10px;
        right: 10px;
      }
    .new-question-form-main
    {
      flex-direction: column!important;
      height: auto!important;
    }
  }
    .game-overview-container {
      .game-overview-content {
        overflow: auto;
        .game-overview-questions {
          overflow: visible;
          .game-overview-question-container {
          .game-overview-question-content {
          .game-overview-question-left {
            .game-overview-question-number {
              width: auto;
              margin-right: 5px;
            }
            .game-overview-question-text {
          .game-overview-question-answer {
            flex-direction: column;
          }
          }
          
        }
        .game-overview-question-right {
          max-height: 2em;
        }
        }
      }
        }
      }
    }
  
  }
}